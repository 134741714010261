import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form as FormikForm, Field } from 'formik';
import Button from '../buttons/button';
import fetch from 'isomorphic-unfetch';
import * as Yup from 'yup';
import ReactGA from 'react-ga';
import Cookies from 'js-cookie';
import courseData from '../../utils/course-data';

const FormContainer = styled.div`
  h1 {
    text-align: left;
    padding: 0 0 10px 0;
  }
  p {
    font-size: 14px;
    color: var(--black);
    text-align: left;
  }
  .radio-input {
    width: 20px;
    margin-right: 5px;
  }
  .radio-label {
    font-weight: bold;
  }

  &.center {
    h1 {
      text-align: center;
    }
  }
`

const SuccessHeader = styled.h2`
  text-align: center;
`

const ErrorMsg = styled.div`
  color: red;
`

const FieldContainer = styled.div`
  width: 100%;
  font-family: 'roboto-mono';
  margin: 15px 0;
  input {
    width: 100%;
  }
`

const fireGTMEvent = () => {
  ReactGA.event({
    category: 'CTA - Button',
    action: 'Click',
    label: 'waitlist-submit',
  })
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
    .required('Required'),
  describe: Yup.string()
    .required('Required')
});
 
export default function WaitListForm ({ close, align }) {
  const [success, setSuccess] = useState(false);

  const submitForm = ({email, firstName, lastName, phone, describe}, ipAddress) => {
    const isBrowser = typeof window !== 'undefined';
    const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
    const pageUri = isBrowser ? window.location.href : null;
    const pageName = isBrowser ? document.title : null;
    const formID = 'e2fa6c70-610d-479a-b4bd-43299d39415a';
    const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/8389530/${formID}`;
    const openHouseURL = courseData.returnNextOpenHouse().event_url;
    const openHouseDate = courseData.returnNextOpenHouse().startDate;

    fireGTMEvent();
		
    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'email',
          value: email,
        },
        {
          name: 'firstname',
          value: firstName
        },
        {
          name: 'lastname',
          value: lastName
        },
        {
          name: 'phone',
          value: phone
        },
        {
          name: 'which_best_describes_your_situation_',
          value: describe
        },
        {
          name: 'open_house_url',
          value: openHouseURL
        },
        {
          name: 'open_house_date',
          value: openHouseDate
        }
      ],
      context: {
        hutk,
        pageUri,
        pageName,
        ipAddress
      },
    };

    fetch(postUrl, {
      method: 'post',
      body: JSON.stringify(body),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      }),
    })
      .then(res => res.json())
      .then(() => {
        setSuccess(true);

        setTimeout(function () {
          close();
          setSuccess(false)
        }, 2000);
      })
      .catch(err => {
        console.log(err);
        setSuccess(false);
      });
  };

  const showSuccess = () => { 
    if (success) {
      return (
        <>
          <SuccessHeader>Thanks!</SuccessHeader>
          <br></br>
          <p>Look out for an email from us in the next 24 hours! Be sure to check your SPAM!</p>
        </>
      )
    } else {
      return (
        <FormContainer className={(align ? align : null)}>
          <h1>Request an Invite</h1>
          <p>Fill out the form below and we'll get back to you as soon as possible.</p>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              describe: ''
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              fetch('https://api.ipify.org/?format=json')
                .then(res => res.json())
                .then((data) => {
                  submitForm(values, data.ip)
                })
                .catch(err => {
                  console.log(err);
                })
            }}
          >
            {({ errors, touched }) => (
              <FormikForm>
                <FieldContainer>
                  <Field
                    placeholder='Email Address'
                    name="email" type="email"
                  />
                  <ErrorMsg>
                    {errors.email && touched.email ? <div>{errors.email}</div> : null}
                  </ErrorMsg>
                </FieldContainer>
              
                <FieldContainer>
                  <Field
                    placeholder='First Name'
                    name='firstName'
                  />
                  <ErrorMsg>
                  {errors.firstName && touched.firstName ? (
                    <div>{errors.firstName}</div>
                  ) : null}
                  </ErrorMsg>
                </FieldContainer>
                
                <FieldContainer>
                  <Field
                    placeholder='Last Name'
                    name="lastName"
                  />
                  <ErrorMsg>
                    {errors.lastName && touched.lastName ? (
                      <div>{errors.lastName}</div>
                    ) : null}
                  </ErrorMsg>
                </FieldContainer>

                <FieldContainer>
                  <Field
                    placeholder='Phone Number'
                    name="phone"
                  />
                  <ErrorMsg>
                    {errors.phone && touched.phone ? (
                      <div>{errors.phone}</div>
                    ) : null}
                  </ErrorMsg>
                </FieldContainer>

                <FieldContainer>
                  <p className='radio-label'>Which best descrbies you?</p>
                  <div>
                    <label htmlFor="applied">
                      <Field id='applied' className='radio-input' type="radio" name="describe" value="I've applied for Parsity" />
                      I've applied for Parsity
                    </label>
                  </div>
                  <div>
                    <label htmlFor="curious">
                      <Field id='curious' className='radio-input' type="radio" name="describe" value="I'm just curious about what Parsity is" />
                      I'm just curious about what Parsity is
                    </label>
                  </div>
                  <div>
                    <label htmlFor='considering'>
                      <Field id='considering' className='radio-input' type="radio" name="describe" value="I'm considering applying for Parsity" />
                      I'm considering applying for Parsity
                    </label>
                  </div>
                  <ErrorMsg>
                    {errors.describe ? (
                      <div>{errors.describe}</div>
                    ) : null}
                  </ErrorMsg>
                </FieldContainer>
                
                <div className={(align ? `text-${align}` : 'text-left')}>
                  <Button text={'Submit'} type='submit' />
                </div>   
              </FormikForm>
            )}
          </Formik>
        </FormContainer>
      )
    }
  };

  return showSuccess();
}