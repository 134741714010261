import React from 'react'
import styled from 'styled-components';
import { Container, Row } from 'react-bootstrap';
import Button from './buttons/linkButton';
import { Link } from 'gatsby';

const ApplyNowSection = styled.section`
  background-color: var(--orange);
  height: 600px;

  h2 {
    margin-top: 200px;
    text-align: center;
    color: var(--white);
    font-weight: bold;
    width: 100%;
  }

  .apply-container {
    display: block;
    margin: 0 auto;
    padding-top: 20px;
  }
`

const ApplyBox = ({text}) => {
  return (
    <ApplyNowSection>
      <Container>
        <Row md={12}>
          <h2>{text}</h2>
          <div className='apply-container'>
            <Link to="/apply">
              <Button link={true} classToAdd='dates-cta' text={'Apply'} />
            </Link>
          </div>
        </Row>
      </Container>
    </ApplyNowSection>
  )
}

export default ApplyBox