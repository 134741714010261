import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap';

const TestimonyContainer = styled.div`
  border: 5px solid var(--black);
  margin: 20px;
  background-color: #fff;
  box-shadow: -15px 15px var(--black);
  position: relative;
  margin-top: 150px;
  
  .test-text {
    padding: 40px 20px 20px 20px;
    margin: 0;
    font-size: .875em;
    text-align: left;
    color: var(--black);
  }

  .test-img-box {
    border: 5px solid var(--black);
    max-width: 150px;
    box-shadow: -10px 10px var(--black);
    position: absolute; 
    top: -90px;
    left: 30px;

    img {
      width: 100%;
      margin: 0;
    }
  }

  .test-info-container {
    padding: 5px 20px 5px 20px;
  }

  .test-name {
    font-size: .875em;
    text-align: left;
    color: var(--black);
  }

  .test-company {
    img {
      width: 100%;
      max-width: 150px;
      display: block;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 767px) {
    .test-padding-top {
      margin-top: 100px;
    }
  }
`

const Testimony = ({image, name, text, logo}) => {
  return (
    <TestimonyContainer>
      <div className='test-img-box'>
        <img alt='' src={image}></img>
      </div>
      <p className='test-text'>{text}</p>
      <hr/>
      <div className='test-info-container'>
        <Row>
          <Col md={7}>
            <p className='test-name'>
              {name}
            </p>
          </Col>
          <Col md={5}>
            <p className='test-company'>
              <img alt='' src={logo}></img>
            </p>
          </Col>
        </Row>
      </div>
    </TestimonyContainer> 
  )
}

export default Testimony;